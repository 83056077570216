<template>
	<div>
		<div class="a-hero-container-small"></div>
		<div class="a-hero-container">
			<b-container class="a-hero-text-container d-flex">
				<div class="a-hero-text-container-inner w-100">
					<h1 class="mb-0 mt-sm-5 mt-xs-3 mt-0">
						<span>{{ $t('Priprav sa') }}</span>
						<span class="w-color-primary z-fancy-underline">
							{{ $t('na život!') }}
							<svg-fancy-underline-icon />
						</span>
					</h1>
					<p class="mb-0 mt-sm-5 mt-3">{{ $t('Nauč sa praktické veci, ktoré potrebuješ do života.') }} </p>
					<p> {{ $t('Rýchlo, zábavne a zadarmo.') }}</p>
					<div class="d-flex flex-column align-items-start">
						<b-button variant="primary" class="a-poppins-btn -wider mt-3" :to="buttonLink">{{ isLoggedIn ? $t('Naše kurzy') : $t('Poď na to!') }}</b-button>
						<b-button variant="secondary" class="a-poppins-btn -wider mt-3" :to="'/pre-ucitelov'">{{ $t('Pre učiteľov') }}</b-button>
					</div>
				</div>
			</b-container>
		</div>

		<a-top-courses v-if="courses" :courses="courses" :category="topCoursesCategory"/>
		<a-latest-courses v-if="courses" :courses="courses"/>

		<a-benefits />
		<a-testimonials />
		<a-stats />
		<!-- <a-references /> -->
		<napisali-o-nas-about />
		<a-competition-modal ref="competition-modal"/>
	</div>
</template>

<script>
import wAxios from '@/plugins/w/axios'
import { mapGetters } from 'vuex'

const api = {
	courses:			() => wAxios.get_data('v1/courses'),
	authCourses:	() => wAxios.get_auth_data('v1/courses'),
	paths:				() => wAxios.get_data('v1/paths'),
	authPaths: 		() => wAxios.get_auth_data('v1/paths'),
}

export default {

	components: {
		'svg-fancy-underline-icon': 	() => import('@/plugins/appzmudri/_theme/icon/fancy-underline.svg?inline'),
		'a-stats': 										() => import('./stats/a-stats'),
		'a-testimonials':							() => import('./testimonials/a-testimonials'),
		// 'a-references': 							() => import('./references/a-references'),
		'a-benefits': 								() => import('./benefits/a-benefits'),
		'a-top-courses':							() => import('./courses/a-top-courses'),
		'a-latest-courses':						() => import('./courses/a-latest-courses'),
		// 'a-launch-competition-modal':	() => import('./modals/a-launch-competition-modal'),
		'a-competition-modal':	() => import('./modals/a-teacher-competition-29-03-2022-modal.vue'),
		'napisali-o-nas-about':				() => import('@/plugins/appzmudri/about/components/NapisaliONas-about.vue'),
	},

	data() {
		return {
			courses: null
		}
	},

	computed: {
		...mapGetters('wAuth', [
			'isLoggedIn',
			'user'
		]),
		isAdmin() {
			return this.user && this.user.profile && this.user.profile.type == 'teacher'
		},
		buttonLink() {
			return this.isLoggedIn ? '/kurzy' : '/registracia'
		},
		topCoursesCategory() {
			return this.isAdmin ? 'teacher' : 'student'
		},
	},

	async mounted() {
		try {
			let courses = await (this.isLoggedIn ? api.authCourses() : api.courses())
			const paths = await (this.isLoggedIn ? api.authPaths() : api.paths())
			courses.forEach(course => {
				course._url = `/kurz/${course.slug || course.id}`
				course._model = 'course'
			})
			// NOTE: Comparison with integer, because '0' == true -> true
			courses = courses.filter(course => course.is_searchable == 1)
			paths.forEach(path => {
				path._url = `/seria/${path.slug || path.id}`
				path._model = 'path'
			})
			this.courses = courses.concat(paths)
		} catch (error) {
			this.courses = {error: 'Nepodarilo sa načítať kurzy'}
		}

		this._showCompetitionModal()
	},

	methods: {
		_showCompetitionModal() {
			if (!this.isLoggedIn || !this.isAdmin) {
				return
			}

			setTimeout(() => {
				if (this.$route.path != '/' || localStorage.getItem('a-teacher-competition-29-03-2022-modal')) {
					return
				}
				this.$refs['competition-modal'].showModal()
				localStorage.setItem('a-teacher-competition-29-03-2022-modal', 'true')
			}, 5000)
		},
	}
}
</script>

<style lang="sass" scoped>
.a-hero-container-small
	background-image: url('./_assets/background-students.png')
	background-position: -270px top

	@media only screen and (min-width: 325px)
		background-position: right top

	@media only screen and (min-width: 445px)
		height: 400px
		background-size: 750px

	@media only screen and (min-width: 600px)
		height: 450px
		background-size: 850px

.a-hero-container
	@media only screen and (min-width: 768px)
		background-image: url('./_assets/background-students.png')

	h1
		display: inline-flex

		@media only screen and (max-width: 359px)
			flex-direction: column

		@media only screen and (min-width: 768px)
			flex-direction: column

		span
			display: inline-flex
			align-self: center

			@media only screen and (min-width: 767px)
					margin-bottom: 0

			@media only screen and (min-width: 768px)
					align-self: flex-start

			&:first-child
				margin-right: 10px

				@media only screen and (max-width: 359px)
					margin-right: 0

				@media only screen and (min-width: 768px)
					margin-right: 0

</style>